<template>
  

    <div class="rw">
      <div class="ava skl"></div>
      <div class="inf">
        <div class="name skl"></div>
        <div class="date skl"></div>
      </div>
      <div class="sts">
        <div class="st skl"></div>
        <div class="st skl"></div>
        <div class="st skl"></div>
        <div class="st skl"></div>
        <div class="st skl"></div>
      </div>
    </div>
 
   
</template>


<script>
  
export default {
  components: {    
 
  },
  data() {
    return {
 
    };
  },
  methods: { 
 
  }
}
</script>
  

<style scoped>
  
.rw{  
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  margin-bottom: 10px;
  gap: 11px;
} 

.rw > *{
  flex-shrink: 0;
}

.ava{
  width: 60px;
  height: 60px;
  border-radius: 50%;  
  margin-left: 10px;
}
.inf{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 30px;
  max-width: 30%;
  width: 100%;
  margin-right: auto;
    gap: 7px;
}
.name{
  width: 100%;
  max-width: 150px;
  height: 21px;
}
.date{
  width: 50px;
  height: 14px;
}
.sts{
  display: flex;
  align-items: center;
  gap: 56px;
  margin-right: 10px;
  height: 43px;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow: hidden;
  flex-shrink: inherit;
  margin-left: auto;
  justify-content: flex-end;
}
.st{
  width: 40px;
  height: 40px;
  border-radius: 50%;  
}


</style>
