<template>
  <div>
    
    <SkGrowerRow 
      v-for="(o, k) in Array(cnt)"
      :key="k"
      />     
  
  </div>
</template>


<script>
  
export default {
  components: {    
 
  },
  props:{
    cnt:{
      type: Number,
      default: 10
    }, 
  },
  data() {
    return {
 
    };
  },
  methods: { 
 
  }
}
</script>
  

<style scoped>
  
.ttl{  
  width: 250px;
  height: 30px;  
  margin-bottom: 10px;  
}
.its{
  display: flex;
  flex-wrap: wrap;
  height: 185px;
  width: calc(100% + 20px);
  overflow-y: hidden;
  margin-bottom: 30px;  
} 



</style>
